export default function StatesFooter() {
  const year = new Date().getFullYear();
  return (
    <div className="flex w-full items-center justify-center">
      <div className="flex w-full max-w-[1440px] flex-col items-center justify-center border-t py-4 dark:border-dark-neutral-700 sm:flex-row md:px-[72px] lg:px-[156px]">
        <p className="body16 font-medium dark:text-gray-400">
          &copy; {year} Sireto Technology. All rights reserved.
        </p>
      </div>
    </div>
  );
}

import cn from 'classnames';
export default function ContentCard({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={cn('w-full overflow-auto p-4', className)}>{children}</div>
  );
}

import { format } from 'date-fns';
export function timeAgo(dateInput: Date | string | number): string {
  const now = new Date();
  const inputDateUTC = new Date(dateInput);
  const inputDateLocal = toLocalDate(inputDateUTC);

  const seconds = Math.floor((now.getTime() - inputDateLocal.getTime()) / 1000);

  const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'week', seconds: 604800 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 },
  ];

  for (const interval of intervals) {
    const count = Math.floor(seconds / interval.seconds);
    if (count > 0) {
      return `${count} ${interval.label}${count > 1 ? 's' : ''} ago`;
    }
  }

  return 'just now';
}

export function seperateDateTime(isoString: string) {
  // Create a Date object
  const dateObject = new Date(isoString);
  const localDateObject = toLocalDate(dateObject);

  // Extract the date (YYYY-MM-DD format)
  const date = localDateObject.toISOString().split('T')[0];

  // Extract the time (HH:mm:ss format)
  const time = localDateObject.toTimeString().split(' ')[0];
  return { date, time };
}

const toLocalDate = (dateObject: Date): Date => {
  const localDateObject = new Date(
    dateObject.getTime() - dateObject.getTimezoneOffset() * 60000
  );
  return localDateObject;
};

export const formatDisplayDate = (
  date: string | Date,
  outputFormat = 'do MMM yyyy'
) => format(new Date(date), outputFormat).toString();

import React from 'react';

export default function FourOFourIcon(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="300"
      height="298"
      viewBox="0 0 300 298"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M43.2124 79.043L44.4772 81.5961L47.3036 82.0099L45.258 84.0009L45.7421 86.8116L43.2124 85.4843L40.6905 86.8116L41.1745 84.0009L39.1289 82.0099L41.9553 81.5961L43.2124 79.043Z"
        fill="#EBEBEB"
      />
      <path
        d="M295.909 199.461L297.174 202.022L300 202.436L297.954 204.419L298.438 207.237L295.909 205.91L293.387 207.237L293.871 204.419L291.825 202.436L294.652 202.022L295.909 199.461Z"
        fill="#F5F5F5"
      />
      <path
        d="M245.864 273.703L247.129 276.256L249.947 276.67L247.902 278.661L248.386 281.472L245.864 280.144L243.342 281.472L243.818 278.661L241.78 276.67L244.599 276.256L245.864 273.703Z"
        fill="#F5F5F5"
      />
      <path
        d="M131.357 33.6796L131.826 34.6322L132.88 34.7883C132.939 34.796 132.994 34.8203 133.04 34.8584C133.085 34.8964 133.119 34.9466 133.137 35.0031C133.155 35.0596 133.157 35.1201 133.142 35.1775C133.126 35.2349 133.095 35.2867 133.052 35.3271L132.271 36.0688L132.451 37.115C132.46 37.1733 132.453 37.2329 132.43 37.2874C132.408 37.3419 132.371 37.3891 132.323 37.4238C132.275 37.4585 132.219 37.4794 132.16 37.4842C132.101 37.4889 132.043 37.4774 131.99 37.4508L131.053 36.9511L130.116 37.4508C130.063 37.4774 130.005 37.4889 129.946 37.4842C129.887 37.4794 129.831 37.4585 129.783 37.4238C129.735 37.3891 129.698 37.3419 129.676 37.2874C129.653 37.2329 129.646 37.1733 129.655 37.115L129.827 36.0688L129.046 35.3271C129.003 35.2862 128.972 35.2337 128.957 35.1757C128.942 35.1178 128.944 35.0568 128.963 35.0001C128.982 34.9433 129.017 34.8932 129.064 34.8557C129.11 34.8181 129.167 34.7948 129.226 34.7883L130.272 34.6322L130.741 33.6796C130.759 33.6122 130.8 33.5529 130.855 33.5105C130.911 33.4682 130.979 33.4453 131.049 33.4453C131.119 33.4453 131.187 33.4682 131.243 33.5105C131.298 33.5529 131.339 33.6122 131.357 33.6796Z"
        fill="#EBEBEB"
      />
      <path
        d="M2.40119 261.549L2.86966 262.494L3.9159 262.65C3.97529 262.659 4.0312 262.683 4.07725 262.722C4.12329 262.76 4.15763 262.811 4.17634 262.868C4.19506 262.925 4.1974 262.986 4.1831 263.044C4.16881 263.102 4.13845 263.155 4.09548 263.197L3.3147 263.931L3.49428 264.977C3.5035 265.035 3.49646 265.095 3.47394 265.149C3.45142 265.204 3.41429 265.251 3.36665 265.286C3.319 265.321 3.26269 265.341 3.20393 265.346C3.14517 265.351 3.08623 265.339 3.03362 265.313L2.09669 264.821L1.15976 265.313C1.10715 265.339 1.04821 265.351 0.98945 265.346C0.930687 265.341 0.874378 265.321 0.826733 265.286C0.779088 265.251 0.741957 265.204 0.719435 265.149C0.696914 265.095 0.689877 265.035 0.699099 264.977L0.87868 263.931L0.0979038 263.197C0.0550763 263.156 0.0246041 263.103 0.00991226 263.046C-0.00477961 262.988 -0.00311081 262.927 0.0147284 262.871C0.0325676 262.814 0.0658713 262.763 0.110905 262.724C0.155938 262.685 0.210918 262.66 0.269673 262.65L1.31591 262.494L1.79219 261.549C1.81305 261.485 1.85376 261.429 1.90846 261.389C1.96317 261.35 2.02906 261.328 2.09669 261.328C2.16432 261.328 2.23021 261.35 2.28492 261.389C2.33962 261.429 2.38033 261.485 2.40119 261.549Z"
        fill="#F5F5F5"
      />
      <path
        d="M23.4607 23.6477L23.9292 24.6003L24.9832 24.7486C25.042 24.758 25.0969 24.7836 25.142 24.8224C25.187 24.8613 25.2203 24.912 25.2382 24.9688C25.256 25.0255 25.2577 25.0861 25.243 25.1438C25.2283 25.2014 25.1978 25.2539 25.155 25.2952L24.3742 26.0759L24.5538 27.1143C24.5663 27.1733 24.5614 27.2346 24.5398 27.2909C24.5183 27.3471 24.4809 27.3959 24.4322 27.4314C24.3835 27.4669 24.3256 27.4875 24.2654 27.4909C24.2053 27.4942 24.1454 27.48 24.0931 27.4501L23.1562 26.9582L22.2193 27.4501C22.167 27.48 22.1071 27.4942 22.047 27.4909C21.9868 27.4875 21.9289 27.4669 21.8802 27.4314C21.8315 27.3959 21.7941 27.3471 21.7725 27.2909C21.751 27.2346 21.7461 27.1733 21.7586 27.1143L21.9304 26.0759L21.1496 25.2952C21.1066 25.2533 21.0763 25.2003 21.062 25.1421C21.0477 25.0838 21.05 25.0228 21.0687 24.9658C21.0874 24.9089 21.1218 24.8583 21.1678 24.8199C21.2139 24.7815 21.2698 24.7568 21.3292 24.7486L22.3754 24.6003L22.8751 23.6477C22.9002 23.591 22.9412 23.5428 22.9932 23.5089C23.0452 23.4751 23.1059 23.457 23.1679 23.457C23.2299 23.457 23.2906 23.4751 23.3426 23.5089C23.3946 23.5428 23.4356 23.591 23.4607 23.6477Z"
        fill="#EBEBEB"
      />
      <path
        d="M22.1921 211.827C22.1936 212.033 22.134 212.235 22.0209 212.407C21.9077 212.578 21.7461 212.713 21.5565 212.792C21.3669 212.872 21.1578 212.894 20.9559 212.855C20.7539 212.816 20.5682 212.717 20.4222 212.572C20.2762 212.427 20.1765 212.242 20.1358 212.041C20.0951 211.839 20.1152 211.63 20.1935 211.44C20.2719 211.25 20.405 211.087 20.5759 210.972C20.7468 210.858 20.9479 210.797 21.1536 210.797C21.4277 210.797 21.6906 210.905 21.8852 211.098C22.0797 211.291 22.19 211.553 22.1921 211.827Z"
        fill="#EBEBEB"
      />
      <path
        d="M170.181 81.8861C170.183 82.0918 170.123 82.2933 170.01 82.4651C169.897 82.6369 169.735 82.7712 169.546 82.851C169.356 82.9308 169.147 82.9525 168.945 82.9133C168.743 82.8742 168.557 82.7759 168.411 82.631C168.265 82.486 168.166 82.301 168.125 82.0994C168.084 81.8978 168.104 81.6886 168.183 81.4984C168.261 81.3082 168.394 81.1455 168.565 81.0311C168.736 80.9166 168.937 80.8555 169.143 80.8555C169.279 80.8545 169.414 80.8804 169.54 80.9317C169.666 80.983 169.78 81.0588 169.877 81.1546C169.973 81.2504 170.05 81.3643 170.102 81.4899C170.154 81.6155 170.181 81.7501 170.181 81.8861Z"
        fill="#EBEBEB"
      />
      <path
        d="M116.341 24.5072C116.341 24.7126 116.28 24.9133 116.166 25.0841C116.051 25.2549 115.889 25.388 115.699 25.4666C115.51 25.5452 115.301 25.5657 115.1 25.5256C114.898 25.4856 114.713 25.3867 114.568 25.2415C114.423 25.0962 114.324 24.9112 114.284 24.7098C114.244 24.5083 114.264 24.2995 114.343 24.1098C114.421 23.92 114.554 23.7579 114.725 23.6438C114.896 23.5297 115.097 23.4688 115.302 23.4688C115.577 23.4708 115.84 23.5809 116.034 23.7752C116.228 23.9695 116.338 24.2324 116.341 24.5072Z"
        fill="#F5F5F5"
      />
      <path
        d="M117.985 277.59C121.07 276.862 122.98 273.772 122.252 270.687C121.524 267.602 118.434 265.692 115.349 266.42C112.264 267.148 110.354 270.239 111.082 273.323C111.81 276.408 114.901 278.319 117.985 277.59Z"
        fill="#F0F0F0"
      />
      <path
        d="M114.696 268.648C113.521 268.646 112.375 269.018 111.425 269.71C110.939 270.823 110.82 272.062 111.085 273.247C111.349 274.432 111.982 275.503 112.894 276.305C113.806 277.106 114.95 277.598 116.159 277.708C117.368 277.818 118.581 277.541 119.623 276.917C120.084 276.064 120.316 275.106 120.295 274.136C120.275 273.167 120.003 272.22 119.506 271.387C119.01 270.554 118.305 269.865 117.462 269.386C116.619 268.907 115.666 268.656 114.696 268.656V268.648Z"
        fill="#E6E6E6"
      />
      <g filter="url(#filter0_i_4337_27800)">
        <path
          d="M70.2505 172.261H22.7402V150.813L70.2505 94.332H92.9867V152.031H104.769V172.261H92.9867V189.812H70.2505V172.261ZM70.2505 152.031V122.487L45.1407 152.031H70.2505Z"
          fill="url(#paint0_linear_4337_27800)"
        />
      </g>
      <g filter="url(#filter1_i_4337_27800)">
        <path
          d="M112.895 142.49C112.895 124.642 116.107 112.149 122.53 105.013C128.953 97.8767 138.742 94.3112 151.895 94.3164C158.209 94.3164 163.396 95.0972 167.456 96.6587C171.144 97.9877 174.522 100.058 177.38 102.741C179.789 105.041 181.83 107.698 183.431 110.619C185.015 113.668 186.209 116.906 186.983 120.254C188.749 127.406 189.63 134.748 189.607 142.115C189.607 159.146 186.726 171.61 180.964 179.506C175.201 187.403 165.278 191.353 151.193 191.358C143.291 191.358 136.907 190.099 132.04 187.579C127.134 185.019 123.002 181.194 120.071 176.5C117.807 173.044 116.045 168.315 114.785 162.314C113.477 155.788 112.844 149.145 112.895 142.49ZM138.77 142.552C138.77 154.524 139.824 162.688 141.933 167.045C144.041 171.402 147.104 173.591 151.122 173.611C152.397 173.626 153.663 173.387 154.845 172.908C156.027 172.43 157.102 171.721 158.009 170.824C159.955 168.966 161.387 166.03 162.303 162.017C163.219 158.004 163.677 151.758 163.677 143.279C163.677 130.817 162.62 122.44 160.507 118.146C158.394 113.851 155.224 111.707 150.997 111.712C146.687 111.712 143.564 113.901 141.628 118.278C139.692 122.656 138.739 130.747 138.77 142.552Z"
          fill="url(#paint1_linear_4337_27800)"
        />
      </g>
      <g filter="url(#filter2_i_4337_27800)">
        <path
          d="M245.193 172.261H197.683V150.813L245.193 94.332H267.929V152.031H279.75V172.261H267.929V189.812H245.193V172.261ZM245.193 152.031V122.487L220.091 152.031H245.193Z"
          fill="url(#paint2_linear_4337_27800)"
        />
      </g>
      <path
        d="M10.2664 81.9349C15.0399 81.9349 18.9097 78.0652 18.9097 73.2917C18.9097 68.5182 15.0399 64.6484 10.2664 64.6484C5.49279 64.6484 1.62305 68.5182 1.62305 73.2917C1.62305 78.0652 5.49279 81.9349 10.2664 81.9349Z"
        fill="#1E5F18"
      />
      <path
        opacity="0.7"
        d="M10.2664 81.9349C15.0399 81.9349 18.9097 78.0652 18.9097 73.2917C18.9097 68.5182 15.0399 64.6484 10.2664 64.6484C5.49279 64.6484 1.62305 68.5182 1.62305 73.2917C1.62305 78.0652 5.49279 81.9349 10.2664 81.9349Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M7.33808 66.0899C6.15276 66.0861 4.97926 66.3344 3.89 66.8195C2.55129 68.3463 1.7567 70.3026 1.63845 72.3631C1.5202 74.4236 2.08541 76.464 3.24005 78.1449C4.39469 79.8257 6.06914 81.0457 7.98482 81.6017C9.90051 82.1578 11.9419 82.0165 13.7695 81.2013C14.9025 79.9056 15.6487 78.2964 15.9173 76.5691C16.186 74.8418 15.9655 73.0708 15.2828 71.471C14.6001 69.8712 13.4844 68.5115 12.0713 67.557C10.6581 66.6025 9.00834 66.0943 7.3222 66.094L7.33808 66.0899Z"
        fill="#195F18"
      />
      <path
        opacity="0.2"
        d="M5.94641 69.6929C5.94641 69.8354 5.90417 69.9746 5.82502 70.0931C5.74588 70.2115 5.63339 70.3039 5.50177 70.3584C5.37016 70.4129 5.22534 70.4272 5.08562 70.3994C4.9459 70.3716 4.81756 70.303 4.71682 70.2022C4.61609 70.1015 4.54749 69.9732 4.5197 69.8334C4.49191 69.6937 4.50617 69.5489 4.56069 69.4173C4.6152 69.2857 4.70752 69.1732 4.82597 69.094C4.94442 69.0149 5.08368 68.9727 5.22614 68.9727C5.41716 68.9727 5.60037 69.0485 5.73545 69.1836C5.87053 69.3187 5.94641 69.5019 5.94641 69.6929Z"
        fill="#183F5F"
      />
      <path
        opacity="0.2"
        d="M16.0304 68.2515C16.0304 68.394 15.9882 68.5332 15.909 68.6517C15.8299 68.7701 15.7174 68.8624 15.5858 68.917C15.4541 68.9715 15.3093 68.9857 15.1696 68.958C15.0299 68.9302 14.9015 68.8616 14.8008 68.7608C14.7001 68.6601 14.6315 68.5318 14.6037 68.392C14.5759 68.2523 14.5902 68.1075 14.6447 67.9759C14.6992 67.8443 14.7915 67.7318 14.91 67.6526C15.0284 67.5735 15.1677 67.5312 15.3101 67.5312C15.5011 67.5312 15.6844 67.6071 15.8194 67.7422C15.9545 67.8773 16.0304 68.0605 16.0304 68.2515Z"
        fill="#183F5F"
      />
      <path
        opacity="0.2"
        d="M14.5881 74.0132C14.5881 74.1557 14.5036 74.295 14.3454 74.4134C14.1871 74.5318 13.9621 74.6242 13.6989 74.6787C13.4356 74.7332 13.146 74.7475 12.8665 74.7197C12.5871 74.6919 12.3304 74.6233 12.129 74.5225C11.9275 74.4218 11.7903 74.2935 11.7347 74.1538C11.6791 74.014 11.7077 73.8692 11.8167 73.7376C11.9257 73.606 12.1104 73.4935 12.3473 73.4144C12.5842 73.3352 12.8627 73.293 13.1476 73.293C13.5296 73.293 13.8961 73.3689 14.1662 73.5039C14.4364 73.639 14.5881 73.8222 14.5881 74.0132Z"
        fill="#183F5F"
      />
      <path
        opacity="0.2"
        d="M10.2689 77.6163C10.2689 77.9012 10.1422 78.1797 9.90476 78.4166C9.66732 78.6535 9.32985 78.8382 8.93501 78.9472C8.54017 79.0562 8.1057 79.0848 7.68654 79.0292C7.26738 78.9736 6.88236 78.8364 6.58016 78.6349C6.27796 78.4335 6.07216 78.1768 5.98879 77.8974C5.90541 77.6179 5.9482 77.3283 6.11175 77.0651C6.2753 76.8018 6.55226 76.5768 6.9076 76.4186C7.26295 76.2603 7.68072 76.1758 8.10809 76.1758C8.68118 76.1758 9.2308 76.3276 9.63603 76.5977C10.0413 76.8679 10.2689 77.2343 10.2689 77.6163Z"
        fill="#183F5F"
      />
      <g opacity="0.3">
        <path
          d="M111.878 61.3741C111.878 61.6632 111.792 61.9458 111.631 62.1861C111.471 62.4263 111.242 62.6135 110.975 62.7238C110.708 62.834 110.414 62.8625 110.13 62.8055C109.847 62.7485 109.587 62.6087 109.383 62.4037C109.179 62.1988 109.041 61.9379 108.985 61.6542C108.93 61.3704 108.96 61.0766 109.071 60.81C109.183 60.5433 109.371 60.3158 109.613 60.1563C109.854 59.9968 110.137 59.9125 110.426 59.9141C110.617 59.9141 110.807 59.9519 110.983 60.0254C111.16 60.0988 111.321 60.2065 111.456 60.3422C111.59 60.4779 111.697 60.6389 111.77 60.816C111.842 60.9931 111.879 61.1828 111.878 61.3741Z"
          fill="#5F4B18"
        />
        <path
          d="M11.9144 117.456C12.4836 117.456 12.945 116.994 12.945 116.425C12.945 115.856 12.4836 115.395 11.9144 115.395C11.3452 115.395 10.8838 115.856 10.8838 116.425C10.8838 116.994 11.3452 117.456 11.9144 117.456Z"
          fill="#5F4B18"
        />
        <path
          d="M182.187 267.967C183.002 267.967 183.662 267.306 183.662 266.491C183.662 265.676 183.002 265.016 182.187 265.016C181.372 265.016 180.711 265.676 180.711 266.491C180.711 267.306 181.372 267.967 182.187 267.967Z"
          fill="#5F4B18"
        />
        <path
          d="M217.671 207.925C218.24 207.925 218.702 207.463 218.702 206.894C218.702 206.325 218.24 205.863 217.671 205.863C217.102 205.863 216.641 206.325 216.641 206.894C216.641 207.463 217.102 207.925 217.671 207.925Z"
          fill="#5F4B18"
        />
        <path
          d="M286.12 22.3353C286.12 22.5391 286.059 22.7384 285.946 22.9079C285.833 23.0774 285.672 23.2095 285.484 23.2875C285.295 23.3655 285.088 23.3859 284.888 23.3461C284.688 23.3063 284.505 23.2082 284.36 23.0641C284.216 22.9199 284.118 22.7363 284.078 22.5364C284.039 22.3364 284.059 22.1292 284.137 21.9409C284.215 21.7526 284.347 21.5916 284.517 21.4784C284.686 21.3651 284.885 21.3047 285.089 21.3047C285.363 21.3047 285.625 21.4133 285.818 21.6065C286.011 21.7998 286.12 22.062 286.12 22.3353Z"
          fill="#5F4B18"
        />
        <path
          d="M89.82 1.55405C89.82 1.75789 89.7596 1.95715 89.6463 2.12663C89.5331 2.29612 89.3721 2.42822 89.1838 2.50622C88.9955 2.58423 88.7883 2.60464 88.5884 2.56487C88.3884 2.5251 88.2048 2.42694 88.0606 2.28281C87.9165 2.13868 87.8184 1.95504 87.7786 1.75512C87.7388 1.5552 87.7592 1.34797 87.8372 1.15965C87.9152 0.971334 88.0473 0.810372 88.2168 0.697126C88.3863 0.58388 88.5856 0.523438 88.7894 0.523438C89.0621 0.52548 89.3231 0.634719 89.5159 0.827556C89.7088 1.02039 89.818 1.28135 89.82 1.55405Z"
          fill="#5F4B18"
        />
        <path
          d="M171.763 55.6276C172.332 55.6276 172.794 55.1662 172.794 54.597C172.794 54.0278 172.332 53.5664 171.763 53.5664C171.194 53.5664 170.732 54.0278 170.732 54.597C170.732 55.1662 171.194 55.6276 171.763 55.6276Z"
          fill="#5F4B18"
        />
      </g>
      <path
        d="M197.375 72.936C206.823 72.936 214.482 65.2771 214.482 55.8293C214.482 46.3816 206.823 38.7227 197.375 38.7227C187.928 38.7227 180.269 46.3816 180.269 55.8293C180.269 65.2771 187.928 72.936 197.375 72.936Z"
        fill="#184E5F"
      />
      <path
        opacity="0.7"
        d="M197.375 72.936C206.823 72.936 214.482 65.2771 214.482 55.8293C214.482 46.3816 206.823 38.7227 197.375 38.7227C187.928 38.7227 180.269 46.3816 180.269 55.8293C180.269 65.2771 187.928 72.936 197.375 72.936Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M191.484 42.922C189.154 42.9148 186.848 43.3851 184.707 44.3039C182.075 47.1958 180.514 50.9014 180.281 54.8043C180.049 58.7071 181.16 62.5719 183.429 65.7557C185.699 68.9394 188.99 71.2501 192.755 72.3034C196.52 73.3567 200.533 73.0891 204.125 71.545C206.352 69.0908 207.818 66.0427 208.346 62.771C208.874 59.4993 208.441 56.1446 207.099 53.1144C205.757 50.0842 203.564 47.5087 200.787 45.7008C198.009 43.8929 194.767 42.9302 191.453 42.9298L191.484 42.922Z"
        fill="#185F5B"
      />
      <path
        opacity="0.2"
        d="M189.778 47.4444C189.778 47.7532 189.687 48.0551 189.515 48.3119C189.344 48.5687 189.1 48.7688 188.814 48.887C188.529 49.0052 188.215 49.0361 187.912 48.9759C187.609 48.9156 187.331 48.7669 187.113 48.5485C186.894 48.3301 186.746 48.0519 186.685 47.749C186.625 47.4461 186.656 47.1321 186.774 46.8468C186.892 46.5614 187.092 46.3176 187.349 46.146C187.606 45.9744 187.908 45.8828 188.217 45.8828C188.631 45.8828 189.028 46.0473 189.321 46.3402C189.614 46.633 189.778 47.0302 189.778 47.4444Z"
        fill="#183F5F"
      />
      <path
        opacity="0.2"
        d="M208.171 45.8858C208.171 46.1946 208.079 46.4965 207.908 46.7533C207.736 47.0101 207.492 47.2102 207.207 47.3284C206.922 47.4466 206.608 47.4775 206.305 47.4173C206.002 47.357 205.724 47.2083 205.505 46.9899C205.287 46.7715 205.138 46.4933 205.078 46.1904C205.018 45.8875 205.049 45.5735 205.167 45.2882C205.285 45.0028 205.485 44.759 205.742 44.5874C205.999 44.4158 206.301 44.3242 206.609 44.3242C207.024 44.3242 207.421 44.4887 207.714 44.7816C208.006 45.0744 208.171 45.4716 208.171 45.8858Z"
        fill="#183F5F"
      />
      <path
        opacity="0.2"
        d="M200.419 63.9325C200.419 64.9297 200.123 65.9045 199.569 66.7336C199.015 67.5628 198.228 68.209 197.306 68.5906C196.385 68.9722 195.371 69.0721 194.393 68.8775C193.415 68.683 192.517 68.2028 191.812 67.4977C191.107 66.7926 190.626 65.8942 190.432 64.9161C190.237 63.9381 190.337 62.9244 190.719 62.0031C191.1 61.0818 191.747 60.2943 192.576 59.7403C193.405 59.1863 194.38 58.8906 195.377 58.8906C196.714 58.8906 197.997 59.4218 198.942 60.3674C199.888 61.3129 200.419 62.5953 200.419 63.9325Z"
        fill="#183F5F"
      />
      <path
        d="M267.951 244.879C269.856 236.808 264.858 228.722 256.787 226.817C248.717 224.912 240.63 229.911 238.725 237.981C236.821 246.051 241.819 254.138 249.889 256.043C257.96 257.947 266.046 252.949 267.951 244.879Z"
        fill="#C4C9A9"
      />
      <path
        opacity="0.3"
        d="M267.951 244.879C269.856 236.808 264.858 228.722 256.787 226.817C248.717 224.912 240.63 229.911 238.725 237.981C236.821 246.051 241.819 254.138 249.889 256.043C257.96 257.947 266.046 252.949 267.951 244.879Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M262.828 229.804C259.5 228.8 255.926 228.982 252.717 230.32C249.508 231.658 246.863 234.068 245.234 237.14C243.605 240.211 243.094 243.753 243.786 247.16C244.479 250.567 246.333 253.628 249.032 255.819C250.431 256.245 251.886 256.46 253.349 256.46C256.449 256.459 259.473 255.499 262.005 253.711C264.537 251.924 266.454 249.396 267.493 246.476C268.531 243.555 268.64 240.385 267.805 237.4C266.97 234.415 265.231 231.762 262.828 229.804Z"
        fill="#BABBA1"
      />
      <path
        d="M239.397 244.827C223.533 253.035 235.364 259.365 257.72 250.528C278.422 242.347 286.312 232.032 267.765 235.235C268.762 239.526 242.836 250.63 239.397 244.827Z"
        fill="#E6E7C7"
      />
      <path
        opacity="0.2"
        d="M163.619 148.474C163.602 153.021 163.163 157.556 162.307 162.021C161.386 166.034 159.954 168.97 158.013 170.828C157.108 171.725 156.035 172.434 154.854 172.913C153.673 173.391 152.408 173.63 151.134 173.615C147.121 173.615 144.058 171.426 141.944 167.049C141.573 166.263 141.257 165.451 141 164.621C136.537 168.454 132.381 172.631 128.57 177.113L124.424 182.001C126.655 184.262 129.229 186.157 132.052 187.614C136.914 190.128 143.295 191.388 151.197 191.393C152.586 191.393 153.929 191.354 155.233 191.276C160.014 184.203 163.969 176.606 167.023 168.634L178.617 138.402L163.619 148.474Z"
        fill="#101828"
      />
      <path
        d="M59.0977 189.716L72.7612 203.38L111.019 167.332C97.832 162.296 72.1913 176.592 59.0977 189.716Z"
        fill="#FFE7B9"
      />
      <path
        d="M106.74 237.343C102.375 232.971 93.0762 223.68 93.0762 223.68L129.125 185.422C134.129 198.578 119.833 224.242 106.74 237.343Z"
        fill="#FFE7B9"
      />
      <g filter="url(#filter3_d_4337_27800)">
        <path
          d="M159.044 151.228L173.949 122.488L145.209 137.409C135.977 142.196 127.453 148.24 119.88 155.367L70.8867 201.487L94.958 225.558L141.078 176.564C148.209 168.99 154.255 160.463 159.044 151.228Z"
          fill="#ECA824"
        />
      </g>
      <g opacity="0.6" filter="url(#filter4_d_4337_27800)">
        <path
          d="M159.044 151.228L173.949 122.488L145.209 137.409C135.977 142.196 127.453 148.24 119.88 155.367L70.8867 201.487L94.958 225.558L141.078 176.564C148.209 168.99 154.255 160.463 159.044 151.228Z"
          fill="white"
        />
      </g>
      <path
        d="M128.431 178.07C133.994 178.07 138.503 173.56 138.503 167.998C138.503 162.435 133.994 157.926 128.431 157.926C122.869 157.926 118.359 162.435 118.359 167.998C118.359 173.56 122.869 178.07 128.431 178.07Z"
        fill="white"
      />
      <path
        d="M128.436 174.473C132.01 174.473 134.908 171.575 134.908 168C134.908 164.425 132.01 161.527 128.436 161.527C124.861 161.527 121.963 164.425 121.963 168C121.963 171.575 124.861 174.473 128.436 174.473Z"
        fill="#183F5F"
      />
      <path
        opacity="0.3"
        d="M103.101 216.908L79.5293 193.337L98.8301 175.176L121.262 197.615L103.101 216.908Z"
        fill="#5F4318"
      />
      <g filter="url(#filter5_di_4337_27800)">
        <path
          d="M64.4268 211.285C46.7812 212.714 40.9332 224.012 38.9344 239.026C37.9194 246.685 37.4666 254.438 31.0408 258.92C30.6542 259.183 30.3632 259.564 30.2112 260.006C30.0593 260.448 30.0546 260.927 30.1979 261.372C30.3412 261.817 30.6248 262.203 31.0062 262.473C31.3876 262.744 31.8462 262.883 32.3135 262.871C56.0022 262.129 67.0502 250.535 68.44 245.694C68.0752 248.224 67.4229 250.705 66.4958 253.088C66.3254 253.508 66.2921 253.972 66.4005 254.412C66.5089 254.853 66.7536 255.248 67.0997 255.541C67.4458 255.834 67.8757 256.011 68.328 256.046C68.7803 256.08 69.2321 255.971 69.6189 255.734C76.2633 251.573 84.602 243.906 85.1564 231.468C80.2687 224.543 64.4268 211.285 64.4268 211.285Z"
          fill="url(#paint3_linear_4337_27800)"
        />
      </g>
      <path
        opacity="0.2"
        d="M64.4268 211.285C46.7812 212.714 40.9332 224.012 38.9344 239.026C37.9194 246.685 37.4666 254.438 31.0408 258.92C30.6542 259.183 30.3632 259.564 30.2112 260.006C30.0593 260.448 30.0546 260.927 30.1979 261.372C30.3412 261.817 30.6248 262.203 31.0062 262.473C31.3876 262.744 31.8462 262.883 32.3135 262.871C56.0022 262.129 67.0502 250.535 68.44 245.694C68.0752 248.224 67.4229 250.705 66.4958 253.088C66.3254 253.508 66.2921 253.972 66.4005 254.412C66.5089 254.853 66.7536 255.248 67.0997 255.541C67.4458 255.834 67.8757 256.011 68.328 256.046C68.7803 256.08 69.2321 255.971 69.6189 255.734C76.2633 251.573 84.602 243.906 85.1564 231.468C80.2687 224.543 64.4268 211.285 64.4268 211.285Z"
        fill="#FFCACA"
      />
      <path
        d="M87.895 236.801L59.6309 208.537L74.6842 205.281L91.1508 221.748L87.895 236.801Z"
        fill="#183F5F"
      />
      <defs>
        <filter
          id="filter0_i_4337_27800"
          x="22.7402"
          y="94.332"
          width="82.0283"
          height="101.243"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.76219" />
          <feGaussianBlur stdDeviation="7.20274" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.883333 0 0 0 0 0.743525 0 0 0 0 0.235556 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_4337_27800"
          />
        </filter>
        <filter
          id="filter1_i_4337_27800"
          x="112.893"
          y="94.3164"
          width="76.7148"
          height="102.805"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.76219" />
          <feGaussianBlur stdDeviation="7.20274" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.883333 0 0 0 0 0.743525 0 0 0 0 0.235556 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_4337_27800"
          />
        </filter>
        <filter
          id="filter2_i_4337_27800"
          x="197.683"
          y="94.332"
          width="82.0674"
          height="101.243"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.76219" />
          <feGaussianBlur stdDeviation="7.20274" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.883333 0 0 0 0 0.743525 0 0 0 0 0.235556 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_4337_27800"
          />
        </filter>
        <filter
          id="filter3_d_4337_27800"
          x="42.0758"
          y="99.4395"
          width="160.684"
          height="160.692"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.76219" />
          <feGaussianBlur stdDeviation="14.4055" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4337_27800"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4337_27800"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_4337_27800"
          x="42.0758"
          y="99.4395"
          width="160.684"
          height="160.692"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.76219" />
          <feGaussianBlur stdDeviation="14.4055" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.395833 0 0 0 0 0.354485 0 0 0 0 0.0362847 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4337_27800"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4337_27800"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_di_4337_27800"
          x="1.28281"
          y="188.236"
          width="112.684"
          height="109.208"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.76219" />
          <feGaussianBlur stdDeviation="14.4055" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.847059 0 0 0 0 0 0 0 0 0 0.152941 0 0 0 0.07 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4337_27800"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4337_27800"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11.5244" />
          <feGaussianBlur stdDeviation="14.4055" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.847059 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_4337_27800"
          />
        </filter>
        <linearGradient
          id="paint0_linear_4337_27800"
          x1="63.7544"
          y1="94.332"
          x2="63.7544"
          y2="189.812"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DEA924" />
          <stop offset="1" stopColor="#FBEE90" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4337_27800"
          x1="151.25"
          y1="94.3164"
          x2="151.25"
          y2="191.358"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DEA924" />
          <stop offset="1" stopColor="#FBEE90" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4337_27800"
          x1="238.716"
          y1="94.332"
          x2="238.716"
          y2="189.812"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DEA924" />
          <stop offset="1" stopColor="#FBEE90" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4337_27800"
          x1="74.5145"
          y1="218.522"
          x2="44.2632"
          y2="252.375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3023" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
}

import { TabGroup, TabList, Tab } from '@headlessui/react';
import cn from 'classnames';

export interface ITabOption {
  value: string;
  label?: string;
  icon?: React.JSXElementConstructor<any>;
  isActive?: boolean;
  tabClassName?: string;
}

export default function Tabs({
  items,
  className,
  onChange,
  currentActiveIndex,
}: {
  items: ITabOption[];
  className?: string;
  onChange?: (value: number) => void;
  currentActiveIndex?: number;
}) {
  return (
    <TabGroup
      selectedIndex={currentActiveIndex}
      onChange={(val) => onChange?.(val)}
    >
      <TabList className={cn('flex h-11 overflow-x-scroll', className)}>
        {items.map((tab, index) => (
          <Tab
            key={`${tab}-${index}`}
            className={cn(
              'body14 no-focus-outline flex h-full items-center gap-x-1 px-4 font-semibold text-gray-600 transition-all hover:text-dark-neutral-700 focus:outline-none focus-visible:outline-none aria-selected:bg-gray-100 aria-selected:text-dark-neutral-700 dark:text-gray-400 hover:dark:text-white dark:aria-selected:bg-dark-neutral-950 aria-selected:dark:text-white aria-selected:dark:shadow-Inner-shadow',
              tab.tabClassName
            )}
          >
            {tab.icon && <tab.icon className="h-[18px] w-[18px]" />}
            <span className="!outline-none">{tab.label || tab.value}</span>
            {tab.isActive && (
              <div className="mb-1 h-1 w-1 rounded-full bg-semantic-error-600"></div>
            )}
          </Tab>
        ))}
      </TabList>
    </TabGroup>
  );
}

import cn from 'classnames';
import FourOFourIcon from '@src/components/icons/404Icon';
import { fourOFour } from '@src/constants';
import StatesFooter from '@src/components/atoms/statesFooter';

export default function FourOFour({ className }: { className?: string }) {
  return (
    <div
      className={cn(
        'flex min-h-calc-85 w-full flex-col items-center justify-center',
        className
      )}
    >
      <div className="relative flex min-h-calc-142 w-full max-w-[1440px] flex-col items-center justify-center gap-6 px-4 py-4 md:px-[72px] lg:px-[156px]">
        <div className="-top-[150px] hidden h-[428px] w-full max-w-[1440px] bg-Radial-gradient sm:absolute"></div>
        <FourOFourIcon className="w-[200px] sm:w-[300px]" />
        <div className="flex flex-col items-center gap-3">
          <span className="h24 font-bold text-neutral-800 dark:text-white">
            {fourOFour.title}
          </span>
          <span className="body14 text-black-700 max-w-[264px] text-center dark:text-white">
            {fourOFour.description}
          </span>
        </div>
      </div>
      <StatesFooter />
    </div>
  );
}

import { toast } from 'react-toastify';
import CopyIcon from '@src/components/icons/copy';
import { ToastId } from '@src/constants/toastId';
import type { ButtonSize } from '@src/components/atoms/buttons/button';
import Button from '@src/components/atoms/buttons/button';
import cn from 'classnames';

export default function CopyableField({
  value,
  textClassName,
  className,
  fieldSize = 'xsmall',
  textOnly = false,
  iconClassName,
  isPreCopyIcon = false,
}: {
  value: string;
  textClassName?: string;
  className?: string;
  fieldSize?: ButtonSize;
  textOnly?: boolean;
  iconClassName?: string;
  isPreCopyIcon?: boolean;
}) {
  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    toast.success('Copied to clipboard', { toastId: ToastId.SUCCESS_TOAST });
  };

  const copyIcon = (
    <CopyIcon
      className={cn(
        'h-5 w-5 text-dark-neutral-800 dark:text-white',
        iconClassName
      )}
    />
  );

  if (textOnly) {
    return (
      <div
        className={cn('flex items-center hover:cursor-pointer', className)}
        onClick={handleCopy}
      >
        {value && isPreCopyIcon && copyIcon}
        <span className={cn('max-w-[125px] truncate', textClassName)}>
          {value}
        </span>
        {value && !isPreCopyIcon && copyIcon}
      </div>
    );
  }

  return (
    <Button
      buttonSize={fieldSize}
      variant="neutral"
      onClick={handleCopy}
      className={cn('flex cursor-pointer items-center', className)}
    >
      <span className={cn('max-w-[125px] truncate', textClassName)}>
        {value}
      </span>
      {value && (
        <CopyIcon className="h-5 w-5 text-dark-neutral-800 dark:text-white" />
      )}
    </Button>
  );
}
